<template>
  <ListReservationToCancel></ListReservationToCancel>
</template>

<script>

import ListReservationToCancel from "@core/layouts/components/admin-components/ListReservationToCancel";

export default {
  name: "ReservationToCancel",

  components: {
    ListReservationToCancel
  }
}
</script>

<style scoped>

</style>